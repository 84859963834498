import React from 'react'
import { Link, graphql } from 'gatsby'
import get from 'lodash/get'
import Helmet from 'react-helmet'
// import Hero from '../components/Hero'
import HeroVideo from '../components/HeroVideo'
import Main from '../components/Main'
import styled from 'react-emotion'
import Button from '../components/Button'

import videoPartners from '../assets/partners-compressed.mp4'
import posterFrame from '../assets/partners-poster.jpg'
import PartnerForm from '../components/PartnerForm'

import Layout from '../components/layout'
import { rhythm } from '../utils/typography'
import Panel from '../components/panel'


export default () => (
  <Layout>
    <Helmet>
      <title>Cruisio: Partner with Us</title>
    </Helmet>
    <HeroVideo
      cover={videoPartners}
      poster={posterFrame}
      headText="Partner"
      subheadText="Move forward by partering with us."></HeroVideo>
    <Main>
      <Panel>
        <h1>Let's Work Together</h1>
        <p>Interested in having our patented technology applied to your vehicle or fleet to improve fuel efficiency? Tell us more about your firm and the opportunity.</p>
      </Panel>
      <Panel className="alt">
        <PartnerForm></PartnerForm>
      </Panel>
    </Main>
  </Layout>
)
