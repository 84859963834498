import React from 'react'
import { Link } from 'gatsby'
import styled from 'react-emotion'

const Form = styled('form')`
  label {
    display: block;
    font-size: .875rem;
    margin: 0 auto;
    margin-bottom: 2rem;
    text-align: left;
    text-transform: uppercase;
    width: 100%;

    input,
    select {
      font-size: 1.5rem;
      margin-top: 0.25rem;
      padding: 0.5rem 1rem;
      text-transform: none;
      width: 100%;
    }

    input {
      border: 1px solid #ccc;
    }

    select {
      font-size: 1.25rem;
      -webkit-appearance: menulist-button;
    }

    &.bot-field {
      opacity: 0;
      display: none;
    }
  }

  button {
    background: #216EDC;
    border: none;
    border-radius: 3px;
    color: #fff;
    font-weight: 600;
    padding: 1rem 1rem;
    text-transform: uppercase;
    width: 100%;
  }
`

export default () => (
  <Form name="partner" method="post" action="/thankyou" data-netlify="true" data-netlify-honeypot="bot-field">
    <input type="hidden" name="form-name" value="partner" />
    <label htmlFor="name">
      Name
      <input type="text" name="name" required/>
    </label>
    <label htmlFor="email">
      Email
      <input type="email" name="email" required/>
    </label>
    <label htmlFor="company" required>
      Company
      <input type="text" name="company" required/>
    </label>
    <label htmlFor="fleetSize">
      Fleet Size
      <select name="fleetSize" required>
        <option value="">(select one)</option>
        <option value="less than 10">Less than 10</option>
        <option value="11-25">11-25</option>
        <option value="26-50">26-50</option>
        <option value="51-99">51-99</option>
        <option value="100+">100+</option>
      </select>
    </label>
    <label htmlFor="vehicleType">
      Vehicle Type
      <input type="text" name="vehicleType" required/>
    </label>
    <label htmlFor="partnershipType">
      Partnership Type
      <select name="partnershipType" required>
        <option value="">(select one)</option>
        <option value="OEM">OEM</option>
        <option value="New Fleet">New Fleet</option>
        <option value="CAN-BUS">CAN-BUS</option>
        <option value="Technology">Technology</option>
      </select>
    </label>
    <label htmlFor="bot-field" className="bot-field">Don't fill this out if you're human!
      <input type="text" name="bot-field"/>
    </label>
    <button type="submit">Send</button>
  </Form>
)
